export default {
  FIRST_NAME: {
    label: 'Имя',
    info: '',
    mask: '',
    type: '',
    placeholder: 'Введите Имя',
  },
  LAST_NAME: {
    label: 'Фамилия',
    info: '',
    mask: '',
    type: '',
    placeholder: 'Введите Фамилию',
  },
  SECOND_NAME: {
    label: 'Отчество (если есть)',
    info: '',
    mask: '',
    type: '',
    placeholder: 'Введите Отчество',
  },
  GENDER: {
    label: 'Пол',
    info: '',
    mask: '',
    type: '',
    selectOption: [
      {valueOption: 'M', nameOption: 'Мужчина'},
      {valueOption: 'F', nameOption: 'Женщина'}]
  },
  EXPER_RADIO: {
    label: 'Стаж на последнем месте работы',
    placeholder:'Выбрать',
    info: '',
    mask: '',
    type: '',
    selectOption: [
      {valueOption: 'До 6 месяцев', nameOption: 'До 6 месяцев'},
      {valueOption: 'От 6 месяцев до года', nameOption: 'От 6 месяцев до года'},
      {valueOption: 'От 1 года до 5 лет', nameOption: 'От 1 года до 5 лет'},
      {valueOption: 'Более 5 летю', nameOption: 'Более 5 лет'},
    ]
  },
  CREDHISTORY_RADIO: {
    label: 'Кредитная история',
    info: '',
    mask: '',
    type: '',
    selectOption: [
      {valueOption: 'Нет, кредиты не брал', nameOption: 'Нет, кредиты не брал'},
      {valueOption: 'Да, есть погашенные кредиты', nameOption: 'Да, есть погашенные кредиты'},
      {valueOption: 'Да, есть непогашенные кредиты', nameOption: 'Да, есть непогашенные кредиты'}
    ]
  },
  EDUCATION:{
    label: 'Образование',
    info: '',
    mask: '',
    type: '',
    selectOption: [
      {valueOption: 'Среднее/среднее специальное', nameOption: 'Среднее/среднее специальное'},
      {valueOption: 'Неполное высшее', nameOption: 'Неполное высшее'},
      {valueOption: 'Высшее', nameOption: 'Высшее'},
      {valueOption: 'Ученая степень', nameOption: 'Ученая степень'},
    ]
  },
  MARITAL_STATUS:{
    label: 'Семейное положение',
    info: '',
    mask: '',
    type: '',
    selectOption: [
      {valueOption: 'Замужем/женат', nameOption: 'Замужем/женат'},
      {valueOption: 'Не замужем/холост', nameOption: 'Не замужем/холост'},
      {valueOption: 'Разведен/разведена', nameOption: 'Разведен/разведена'},
    ]
  },
  MINOR_CHILDREN: {
    label: 'Дети до 18 лет',
    info: '',
    mask: '',
    type: ''
  },
  NOT_RELATED: {
    label: 'Я не являюсь лицом или родственником <br class="mobileOnly">любого из лиц, перечисленных ниже:',
    info: '',
    mask: '',
    type: ''
  },
  MY_OWN_BENEFIT: {
    label: 'Я действую к собственной выгоде.<br class="mobileOnly"> Иные лица, к выгоде которого я действую (выгодоприобретатель), отсутствуют',
    info: '',
    mask: '',
    type: ''
  },
  ACTIONS_CONTROL: {
    label: 'Лица, прямо или косвенно контролирующие мои действия, <br class="mobileOnly">в том числе имеющие возможность определять решения, принимаемые мной, отсутствуют',
    info: '',
    mask: '',
    type: ''
  },
  IS_ASSIGNMENT_RIGHT:{
    label: 'С правом Кредитора уступить третьим лицам права(требования) по договору согласен',
    info: '',
    mask: '',
    type: ''
  },
  CALC_SUMM: {
    label: 'Сумма займа',
    info: '',
    mask: '',
    type: '',
    selectOption: [{valueOption: 20000, nameOption: 20000},
      {valueOption: 21000, nameOption: 21000},
      {valueOption: 22000, nameOption: 22000},
      {valueOption: 23000, nameOption: 23000},
      {valueOption: 24000, nameOption: 24000},
      {valueOption: 25000, nameOption: 25000},
      {valueOption: 26000, nameOption: 26000},
      {valueOption: 27000, nameOption: 27000},
      {valueOption: 28000, nameOption: 28000},
      {valueOption: 29000, nameOption: 29000},
      {valueOption: 30000, nameOption: 30000},
      {valueOption: 31000, nameOption: 31000},
      {valueOption: 32000, nameOption: 32000},
      {valueOption: 33000, nameOption: 33000},
      {valueOption: 34000, nameOption: 34000},
      {valueOption: 35000, nameOption: 35000},
      {valueOption: 36000, nameOption: 36000},
      {valueOption: 37000, nameOption: 37000},
      {valueOption: 38000, nameOption: 38000},
      {valueOption: 39000, nameOption: 39000},
      {valueOption: 40000, nameOption: 40000},
      {valueOption: 41000, nameOption: 41000},
      {valueOption: 42000, nameOption: 42000},
      {valueOption: 43000, nameOption: 43000},
      {valueOption: 44000, nameOption: 44000},
      {valueOption: 45000, nameOption: 45000},
      {valueOption: 46000, nameOption: 46000},
      {valueOption: 47000, nameOption: 47000},
      {valueOption: 48000, nameOption: 48000},
      {valueOption: 49000, nameOption: 49000},
      {valueOption: 50000, nameOption: 50000}
    ]
  },
  BIRTHDAY: {
    label: 'Дата рождения',
    info: '',
    mask: '##.##.####',
    type: 'tel'
  },
  BIRTHPLACE: {
    label: 'Место рождения',
    info: '',
    mask: '',
    type: ''
  },
  CALC_WEEK: {
    label: 'Срок займа',
    info: '',
    mask: '',
    type: '',
    selectOption: []
  },
  CONST_INCOME: {
    label: 'Сумма постоянного дохода / пенсии в месяц (руб.):',
    placeholder:'Введите сумму',
    info: '',
    mask: '##############',
    type: 'tel'
  },
  EMAIL: {
    label: 'Email',
    info: '',
    mask: '',
    type: '',
    placeholder: 'Введите E-mail'
  },
  EMAILreq: {
    label: 'Email',
    info: '',
    mask: '',
    type: '',
    placeholder: 'Введите E-mail'
  },
  IS_ADDRESS_COINCIDES: {
    label: 'Адрес регистраци совпадает с адресом проживания',
    info: '',
    mask: '',
    type: ''
  },

  IS_I_CERTIFY_REGISTRATION: {
    label: 'Подтверждаю достоверность введенных данных и даю согласие на обработку персональных данных <a href="https://lnd.msk.finmoll.com/assets/pdf/consent_to_PD_processing.pdf" target="_blank">Подробнее</a>',
    info: '',
    mask: '',
    type: ''
  },

  GENERAL_TERMS_OF_MICROLOAN: {
    label: 'Общие условия договора микрозайма<br>с ООО МКК ФИНМОЛЛ',
    info: '',
    mask: '',
    type: ''
  },

  RULES_FOR_MICROLOAN: {
    label: 'Правила предоставления микрозаймов<br> ООО МКК ФИНМОЛЛ',
    info: '',
    mask: '',
    type: ''
  },

  APPLICATION_MICROLOAN: {
    label: 'Заявление на предоставление займа',
    info: '',
    mask: '',
    type: ''
  },

  AGREEMENT_IU: {
    label: 'Индивидуальные условия',
    info: '',
    mask: '',
    type: ''
  },
  AGREEMENT_PDN: {
    label: 'Согласие ПДН',
    info: '',
    mask: '',
    type: ''
  },

  LOAN_WITH_INSURANCE: {
    label: 'Оформить заём со страховкой',
    info: '',
    mask: '',
    type: ''
  },

  REQURENT_PAY: {
    label: 'Согласие с  Соглашением о применении рекуррентных платежей: ',
    info: '',
    mask: '',
    type: ''
  },

  THIRD_PARTIES: {
    label: 'С правом Кредитора уступить третьим лицам<br>права(требования) по договору согласен',
    info: '',
    mask: '',
    type: ''
  },
  ACCEPTANCE_OF_THE_TERMS: {
    label: 'Согласие с Общими условиями',
    info: '',
    mask: '',
    type: ''
  },
  AGREEMENT_WITH_THE_RULES: {
    label: 'Согласие с Правилами выдачи и<br>т.п. займа',
    info: '',
    mask: '',
    type: ''
  },
  AGREEMENT_LONGATSIA_RULES: {
    label: 'Согласие с правилами лонгации',
    info: '',
    mask: '',
    type: ''
  },
  AGREEMENT_DATA_TRANSMISSION: {
    label: 'Согласие на передачу данных<br> в Мегафон',
    info: '',
    mask: '',
    type: ''
  },
  AGREEMENT_KI_REQUEST: {
    label: 'Согласие на запрос в КИ',
    info: '',
    mask: '',
    type: ''
  },


  IS_I_CERTIFY_MARKETING: {
    label: 'Подтверждаю достоверность введенных данных и даю согласие на получение рекламы <a href="https://lnd.msk.finmoll.com/admd/upload/consentToAdvertising.pdf" target="_blank">Подробнее</a>',
    info: '',
    mask: '',
    type: ''
  },
  MEETING_DATE: {
    label: 'Желаемая дата встречи с менеджером',
    info: '',
    mask: '',
    type: 'date',
  },
  MEETING_HOUR: {
    label: 'Желаемое время встречи с менеджером',
    info: '',
    mask: '',
    type: 'time'
  },
  OTHER_SOURCES_OF_INCOME: {
    label: 'Сумма дополнительного дохода в месяц (руб.):',
    placeholder:'Введите сумму',
    info: '',
    mask: '##############',
    type: 'tel'
  },
  PASSPORT_DATE: {
    label: 'Дата выдачи паспорта',
    info: '',
    mask: '##.##.####',
    type: 'tel'
  },
  PASSPORT_DEP: {
    label: 'Кем выдан',
    placeholder:'Выберите из выпадющего списка или введите точно как в паспорте',
    info: '',
    mask: '',
    type: ''
  },
  PASSPORT_DEP_CODE: {
    label: 'Код подразделения',
    info: '',
    mask: '###-###',
    type: 'tel',
    placeholder: '___-___',
  },
  PASSPORT_NUMBER: {
    label: 'Номер паспорта',
    info: '',
    mask: '######',
    type: 'tel',
    placeholder:'______',
  },
  PASSPORT_SERIES: {
    label: 'Серия паспорта',
    info: '',
    mask: '####',
    type: 'tel',
    placeholder:'____',
  },
  PHONE: {
    label: 'Телефон для связи',
    info: '',
    mask: '+7 (###) ###-##-##',
    placeholder: 'Введите телефон',
    type: 'tel'
  },
  PRODUCT_ID: {
    label: 'Тип продукта',
    info: '',
    mask: '',
    type: ''
  },
  REGISTRATION_ADDRESS: {
    label: 'Адрес регистрации',
    placeholder:'Область, город, улица, дом, кв',
    info: '',
    mask: '',
    type: ''
  },
  REGISTRATION_ADDRESS_DADATA: {
    label: '',
    info: '',
    mask: '',
    type: ''
  },
  RESIDENTIAL_ADDRESS: {
    label: 'Фактический адрес проживания',
    placeholder:'Область, город, улица, дом, кв',
    info: '',
    mask: '',
    type: ''
  },
  RESIDENTIAL_ADDRESS_DADATA: {
    label: '',
    info: '',
    mask: '',
    type: ''
  },
  WORK_JOB: {
    label: 'Наименование организации:',
    placeholder:'Например, ИП Иванов',
    info: '',
    mask: '',
    type: ''
  },
  null: {
    label: '',
    info: '',
    mask: '',
    type: ''
  },
  finderFio:{
    label: 'Поиск по ФИО клиента',
    info: '',
    mask: '',
    type: ''
  },
  finderPhone:{
    label: 'Поиск по номеру телефона',
    info: '',
    mask: '+7 (###) ###-##-##',
    type: ''
  },
  WHERE_TO_FIND_US:{
    label: 'Откуда о нас узнали',
    info: '',
    mask: '',
    type: '',
    selectOption:[]
  },
  ReCallDate:{
    label: 'Дата звонка',
    info: '',
    mask: '',
    type: 'date',
    selectOption:[]
  },
  ReCallTime:{
    label: 'Время звонка',
    info: '',
    mask: '##:##',
    type: 'time',
    selectOption:[]
  },
  CAR_NUMBER:{
    label: 'Гос. номер автомобиля',
    info: 'пример: х010хх777',
    mask: '',
    type: '',
  },
  FINANCIAL_POSITION:{
    label: 'Финансовое положение',
    info: '',
    mask: '',
    type: '',
  },
  PURPOSE_OF_THE_LOAN:{
    label: 'Цель займа',
    info: '',
    mask: '',
    type: '',
  },
  SOURCE_OF_INCOME:{
    label: 'Источник дохода',
    info: '',
    mask: '',
    type: '',
  },
  SmsConfCode:{
    label: 'Код подтверждения',
    info: '',
    mask: '',
    type: 'tel',
  },
  pdlSmsConfCode:{
    label: 'Код подтверждения',
    info: '',
    mask: '####',
    type: 'tel',
  },
  ASP:{
    label: '<span>Я согласен\\на и ознакомлен\\на со следующим</span><br><a href="https://finmoll.ru/documents/soglashenie-ob-ispolzovanii-asp.pdf" target="_blank">Согласие об использовании аналога собственноручной подписи</a>' ,
    info: '',
    mask: '',
    type: '',
  },
  AVERAGE_EXPENSES_PER_MONTH:{
    label: 'Средние расходы в месяц (руб)',
    placeholder:'Введите сумму',
    info: '',
    mask: '#######',
    type: 'tel',
  },
  AVERAGE_MONTHLY_EXPENSES_FOR_LOAN_PAYMENTS:{
    label: 'Средние расходы в месяц на платежи по кредитам (руб)',
    placeholder:'Введите сумму',
    info: '',
    mask: '#######',
    type: 'tel',
  },
  INN:{
    label:'ИНН',
    placeholder:'введите свой ИНН',
    info:'',
    mask:'',
    type:'tel'
  },
  // todo не показываем - надо бы убрать
  WORK_JOB_INN:{
    label:'ИНН работадателя',
    placeholder:'введите ИНН работадателя',
    info:'',
    mask:'',
    type:'tel'
  },
  WORK_JOB_PHONE:{
    label:'Рабочий телефон',
    placeholder:'введите рабочий телефон',
    info:'',
    mask:'+7 (###) ###-##-##',
    type:'tel'
  },
  CONTACT_PHONE:{
    label:'Номер телефона контактного лица',
    placeholder:'введите номер телефона',
    info:'',
    mask:'+7 (###) ###-##-##',
    type:'tel'
  },
  CONTACT:{
    label:'Контактное лицо',
    placeholder:'Введите фио',
    info:'',
    mask:'',
    type:''
  },
  //
  PAYMENT_TYPE:{
    label:'Выберите способ получения займа:',
    placeholder:'',
    info:'',
    mask:'',
    type:'radio',
    selectOption: [
      {valueOption: 'card', nameOption: 'На любую карту'},
      {valueOption: 'sbp', nameOption: 'СБП'},
      ]
  },
  SNILS:{
    label:'СНИЛС',
    placeholder:'Введите снилс',
    info:'',
    mask:'###-###-###-##',
    type:'tel'
  },
  rangeValuePeriod:{
    label:'Выберите срок',
    selectOption: []
  },
  finderBankSbp:{
    label:'поиск банка',
    mask:'',
  }
}
