<template>
	<div class="inputBox">
    <div class="inputBoxWrap">
      <input
        v-if="item.mask.length > 0"
        v-bind="$attrs"
        v-model.trim="value"
        @blur="eventBlur"
        @focus="eventFocus"
        v-mask="item.mask"
        :name="refs"
        :type="item.type"
        :placeholder="item.label"
        :class="{ errFieldBorder: showErrorMessage && status !== 0 }"
      />
      <input
        v-else
        v-model.trim="value"
        @blur="eventBlur"
        @focus="eventFocus"
        :name="refs"
        :type="item.type"
        :placeholder="item.placeholder"
        :class="{ errFieldBorder: showErrorMessage && status !== 0 }"
      />
      <label>{{ item.label }}</label>
      <p class="errorMsg" v-show="showErrorMessage">{{ errorMsg }}</p>
    </div>
  </div>
</template>

<script>
/* todo suka blyat v-mask, сломал при пустой маске ввод любых символов
    нужна новая маска
*   */
import DataForInput from '@/store/repeatLoan/longFormOptions';
import { mapState } from 'vuex';
import tracing from '@/common/tracing';

export default {
	inheritAttrs: false,

	name: 'formInput',

	data: function () {
		return {
			item: DataForInput[this.refs],
			isTimeToShowError: false
		};
	},

	props: {
		refs: {
			type: String,
			default: 'null'
		},
		required: {
			type: Boolean,
			default: true
		}
	},

	computed: {
		status() {
			return this.statusVuex[this.refs].code;
		},
		errorMsg() {
			return this.statusVuex[this.refs].msg;
		},
		showErrorMessage() {
			return this.statusVuex[this.refs].ShowError;
		},
		value: {
			get() {
				return this.valueVuex[this.refs];
			},
			set(value) {
				this.$store.dispatch('repeatLoan/saveSendData', {
					target: this.refs,
					value: value
				});
			}
		},
		...mapState({
			valueVuex: (state) => state.repeatLoan.sendData,
			statusVuex: (state) => state.repeatLoan.workData
		})
	},

	methods: {
		eventBlur: function () {
			if (this.required) {
				this.$store.dispatch('repeatLoan/errorShow', {
					target: this.refs,
					value: true
				});
			}
		},
		eventFocus: function () {
			this.$store.dispatch('repeatLoan/errorShow', {
				target: this.refs,
				value: false
			});
			tracing.traceInput({
				action: 164,
				refs: this.refs,
				value: this.valueVuex[this.refs]
			});
		}
	}
};
</script>

<style scoped></style>
